import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "./question.css";

const SingleQuestion = ({ question, isOpen, onOpen }) => {
  return (
    <div className="SingleQuestion">
      <header className="SingleQuestion-header" onClick={onOpen}>
        <div className="SingleQuestion-title">{question.title}</div>
        <button
          type="button"
          className="SingleQuestion-button"
          onClick={onOpen}
        >
          {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </header>

      <div className="SingleQuestion-info">
        {isOpen && <p className="info">{question.info}</p>}
      </div>
    </div>
  );
};

export default SingleQuestion;
