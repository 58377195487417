import React from "react";
import "./article.css";
import { Link } from "react-router-dom";

const Article = ({ imageUrl, date, title, slug }) => {
  // define scroll to top function
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let subURL = `https://momentous-health.com/blogs/${slug}`;

  if (slug === "How-can-neurocognitive-rehab-help-me") {
    subURL =
      "https://momentous-health.com/blogs/benefit-of-cognitive-rehabilitations/";
  }

  if (slug === "The-Science-of-Memory-and-Forgetting") {
    subURL =
      "https://momentous-health.com/blogs/the-science-of-memory-and-forgetting/";
  }

  if (slug === "How-can-a-neuropsychological-evaluation-help-me") {
    subURL =
      "https://momentous-health.com/blogs/can-neuropsychological-evaluation-help-me/";
  }

  return (
    <div className="BH__blog-container_article">
      <div className="BH__blog-container_article-image">
        <img src={imageUrl} alt="img" />
      </div>
      <div className="BH__blog-container_article-content">
        <p>{date}</p>
        <h3>{title}</h3>
        <h4>
          <a href={subURL}>Read the Full Article</a>
        </h4>
      </div>
    </div>
  );
};

export default Article;
