import React from "react";
import "./resource.css";
import { Article } from "../../components";
import { blog01, blog02, blog03, blog04, blog05 } from "./imports";

// call the backend to get the data !
const Resource = () => {
  return (
    <div className=" section__padding" id="resource">
      <div className="resource-heading">
        <h1 className="gradient__text">
          <a href="https://www.momentous-health.com/blogs/"> Blogs</a>
        </h1>
      </div>
      <div className="resource-container">
        <div className="resource-container_GroupA">
          <Article
            imageUrl={blog01}
            date="Jan 1, 2023"
            title="Guidance for Managing Dementia Concerns"
            slug="guidance-for-managing-dementia-concerns"
          ></Article>
        </div>
        <div className="resource-container_GroupB">
          <Article
            imageUrl={blog02}
            date="Feb 2, 2023"
            title="The Science of Memory and Forgetting"
            slug="The-Science-of-Memory-and-Forgetting"
          ></Article>
          <Article
            imageUrl={blog03}
            date="Mar 8, 2023"
            title="How can neurocognitive rehabilitation help me?"
            slug="How-can-neurocognitive-rehab-help-me"
          ></Article>
          <Article
            imageUrl={blog04}
            date="April 7, 2023"
            title="What is Alzheimer’s Disease?"
            slug="what-is-alzheimers-disease"
          ></Article>
          <Article
            imageUrl={blog05}
            date="May 16, 2023"
            title="How can a neuropsychological evaluation help me?"
            slug="How-can-a-neuropsychological-evaluation-help-me"
          ></Article>
        </div>
      </div>
    </div>
  );
};

export default Resource;
