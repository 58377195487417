import React from "react";
import {
  Header,
  Services,
  Locations,
  Notion,
  Resource,
  Footer,
} from "../../sections";
import { Review, CTA, Title, FAQs, Navbar } from "../../components";

import LazyLoad from "react-lazyload";

const Home = () => {
  return (
    <React.Fragment>
      <Title />
      <Navbar />
      <Header className="gradient__bg-header" />
      <LazyLoad height={200}>
        <Services />
      </LazyLoad>
      <LazyLoad height={200}>
        <Locations />
      </LazyLoad>
      <LazyLoad height={200} once>
        <Notion />
      </LazyLoad>
      <LazyLoad height={200} once>
        <Review />
      </LazyLoad>
      <LazyLoad height={200} once>
        <Resource />
      </LazyLoad>
      <LazyLoad height={200} once>
        <FAQs />
      </LazyLoad>
    </React.Fragment>
  );
};

// TODO: 2023-10-10
// get rid of the CTA , might add another communication box there

export default Home;
