import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const ReviewCard = ({ author, content, rating }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        height: 320,
        marginTop: 1,
        marginBottom: 1,
        background: "#01050d",
        borderColor: "#262626",
        borderRadius: "10px",
        fontFamily: "Manrope, sans-serif",
        "@media (max-width: 568px)": {
          fontSize: "14px", // Adjust font size for smaller screens
          height: 250,
        },
      }}
    >
      <CardContent>
        <Typography
          color="text.secondary"
          sx={{
            fontSize: "18px",
            color: "#8c8c8c",
            fontWeight: 500,
            fontFamily: "Manrope, sans-serif",
            "@media (max-width: 1400px)": {
              fontSize: "16px", // Adjust font size for smaller screens
            },
            "@media (max-width: 800px)": {
              marginBottom: "8px",
              lineHeight: 1.2,
            },
          }}
          paragraph
        >
          {content}
        </Typography>

        <Grid container justify="space-between" justifyContent="space-between">
          <Rating
            align="left"
            sx={{
              fontSize: "1.3rem",
              color: "#d4b106",
            }}
            value={rating}
            precision={0.5}
            readOnly
          />

          <Typography
            align="right"
            fontFamily="Manrope, sans-serif"
            sx={{
              color: "#bfbfbf",
              fontSize: "18px",
              "@media (max-width: 1400px)": {
                fontSize: "16px",
              },
              "@media (max-width: 568px)": {
                fontSize: "14px", // Adjust font size for smaller screens
                height: 350,
              },
            }}
            gutterBottom
          >
            {author}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
