const sublinks = [
  {
    page: "About",
    url: "/about",
  },
  {
    page: "Specialty",
    url: "/specialty",
  },
  {
    page: "Team",
    url: "/team",
  },
  {
    page: "Pricing",
    url: "/pricing",
  },
  {
    page: "Blogs",
    url: "https://momentous-health.com/blogs/",
  },
];

export default sublinks;
