import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";
import sublinks from "../data.jsx";
import sidebarLink from "./data.jsx";
import "./navbar.css";
import Sidebar from "./sidebar";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const MH_Logo =
  "https://cdn-blob-momentous-health.azureedge.net/mh-web-image/MH-logo.svg";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const titleCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Navbar = () => {
  // handle the submenu burger icon
  const [SidebarShow, SetSubmenu] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);

  // get the pathname
  const currentURL = useLocation();
  const { pathname } = currentURL;

  const handleSubmenu = () => {
    return SetSubmenu(!SidebarShow);
  };

  // when window got resized, let's close the burger icon
  const handleResize = () => {
    if (window.innerWidth > 1104) {
      SetSubmenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLinkClick = (index) => {
    setSelectedLink(index);
  };

  window.addEventListener("scroll", function () {
    var navbar = document.getElementById("section__padding_nav");
    if (window.scrollY) {
      navbar.classList.add("with-line");
    } else {
      navbar.classList.remove("with-line");
    }
  });

  return (
    <div className="section__padding_nav" id="section__padding_nav">
      <div className="nav-header">
        <div className="navbar-header-links">
          <div className="nav-logo" onClick={scrollToTop}>
            <HashLink smooth to="/">
              <img src={MH_Logo} alt="momentous-health-logo" />
            </HashLink>
          </div>

          {/* The main nav items*/}
          <div className="nav-links-container">
            <ul className="nav-links" onClick={scrollToTop}>
              {sidebarLink.map((sublink, index) => {
                const { page, url } = sublink;

                return (
                  <li key={index}>
                    <p className="link-btn">
                      {url.startsWith("https") ? (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {page}
                        </a>
                      ) : (
                        <HashLink
                          className={`text-box ${
                            titleCase(pathname) === url ? "clicked" : ""
                          }`}
                          smooth
                          to={url}
                        >
                          {page}
                        </HashLink>
                      )}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="nav-signin">
          <button type="button">
            <a href="https://forms.office.com/r/T1W9Nktpyv" target="_blank">
              Refer
            </a>
          </button>
        </div>

        <div className="sidebar">
          <div
            type="button"
            className="burger-menu"
            onClick={() => handleSubmenu()}
          >
            {!SidebarShow ? <GiHamburgerMenu /> : <RiCloseFill />}
          </div>
          {SidebarShow && <Sidebar data={sublinks} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
