import React from "react";
import SingleQuestion from "./question";
import { data } from "./data";
import "./faqs.css";
import { useState } from "react";

const FQAs = () => {
  const questions = data;

  const [openQuestionId, setOpenQuestionId] = useState(null);

  const handleOpenQuestion = (id) => {
    setOpenQuestionId(id === openQuestionId ? null : id);
  };

  return (
    <div className="FAQ section__padding" id="FAQs">
      <div className="FAQ-heading gradient__text">FAQs</div>

      <div className="FAQ-questions">
        {questions.map((question) => {
          return (
            <SingleQuestion
              className="FAQ-question-items"
              question={question}
              isOpen={openQuestionId === question.id}
              onOpen={() => handleOpenQuestion(question.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FQAs;
