import React from "react";
import "./footer.css";
import { RiTwitterFill } from "react-icons/ri";
import { SlSocialInstagram } from "react-icons/sl";
import {
  AiOutlineYoutube,
  AiOutlineLinkedin,
  AiOutlineMail,
} from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { LiaFaxSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@mui/material";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer section__padding">
      <div className="footer-links">
        <div className="footer-links_logo">
          <img
            src={require("../../assets/LOGO-MH-WEB.png")}
            alt="company logo"
          />

          <p>
            <span>
              <CiLocationOn /> Address: Danville, CA
            </span>
          </p>

          <p>
            <span>
              <FiPhoneCall /> Phone: 800-679-1730
            </span>
          </p>

          <p>
            <span>
              <LiaFaxSolid /> Fax: 866-562-7982
            </span>
          </p>

          <p>
            <span>
              <AiOutlineMail /> admin@momentous-health.com
            </span>
          </p>
        </div>

        <div className="footer-links_div">
          <h4>Company</h4>
          <p>
            <a href="https://www.momentous-health.com/blogs/" target="_blank">
              Blog
            </a>
          </p>
          <p>Hiring</p>
          <p>Referral</p>
        </div>

        <div className="footer-links_div ">
          <h4>Follow us</h4>
          <div className="icon">
            <a
              href="https://twitter.com/HealthMomentous"
              target="_blank"
              aria-label="momentous health twitter"
            >
              <RiTwitterFill />
            </a>
          </div>
          <div className="icon">
            <a
              href="https://www.linkedin.com/company/momentous-health/about/?viewAsMember=true"
              target="_blank"
              aria-label="momentous health linkedin"
            >
              <AiOutlineLinkedin />
            </a>
          </div>
          <div className="icon">
            <a
              href="https://www.youtube.com/@brain_cafe"
              target="_blank"
              aria-label="momentous health youtube"
            >
              <AiOutlineYoutube />
            </a>
          </div>
          <div className="icon">
            <a
              href="https://www.instagram.com/brain_cafe_/"
              target="_blank"
              aria-label="momentous health instagram"
            >
              <SlSocialInstagram />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-copyright">
        <p>
          {" "}
          Copyright &copy; Momentous Health Inc. {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
