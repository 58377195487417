import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Sidebar = ({ data }) => {
  return (
    <div className="sidebar-container">
      <div className="sidebar-links">
        <ul>
          {data.map((sublink, index) => {
            const { page, url } = sublink;
            return (
              <div className="sidebar-div" onClick={scrollToTop}>
                <li className="sidebar-link-item" key={index}>
                  <a href={url} style={{ textDecoration: "none" }}>
                    <p>{page}</p>
                  </a>
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
